import { render } from 'react-dom';
import { Globals } from '@bwoty-web/ui-kit';
import Newsletter from '../shared/footer/components/Newsletter';

const initialStateFooter = window.__INITIAL_STATE_FOOTER__;

// eslint-disable-next-line camelcase, no-undef
__webpack_public_path__ = `${initialStateFooter?.baseUrl}/static/`;

const newsletterRoot = document.getElementById('newsletter');

if (newsletterRoot) {
  render((
    <Globals siteId={initialStateFooter.siteId} >
      <Newsletter
        siteId={initialStateFooter.siteId}
        labels={initialStateFooter.content.newsletter}
        baseUrl={initialStateFooter.baseUrl}
        standalone={initialStateFooter.standalone}
      />
    </Globals>
  ), newsletterRoot);
}
