/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useIsGlobetrotter } from '@bwoty-web/ui-kit/hooks';
import { Icon } from '@bwoty-web/ui-kit';
import { COMMUNICATION_EMAIL } from '@bwoty-web/ui-kit/icons';
import { subscribeToNewsletter } from '../services/MemberService';
import { logSubscription } from '../../../client/utils/LogUtils';
import LinkList from './LinkList';

import './newsletter.scss';
import './globeNewsletter.scss';

const Newsletter = ({ baseUrl, siteId, labels, standalone }) => {
  const isGlobe = useIsGlobetrotter();

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [isValid, setIsValid] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [validateOnInput, setValidateOnInput] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [subscriptionSuccessful, setSubscriptionSuccessful] = useState(false);

  useEffect(() => {
    const emailInput = document.getElementById('email-input');
    if (emailInput && !standalone) {
      emailInput.focus();
    }
  }, [standalone]);

  const validateEmail = (email) => {
    return emailRegex.test(email);
  };

  const validateAndSubscribe = () => {
    if (inputValue.length === 0) {
      setIsValid(false);
      setShowValidation(false);
      return;
    }

    const valid = validateEmail(inputValue);

    if (valid) {
      logSubscription();
      subscribeToNewsletter(baseUrl, siteId, inputValue);
    }

    setIsValid(valid);
    setValidateOnInput(true);
    setShowValidation(true);
    setSubscriptionSuccessful(valid);
  };

  const submit = (event) => {
    event.preventDefault();

    validateAndSubscribe();
  };

  const updateInput = (event) => {
    const { value } = event.target;
    setInputValue(value);

    if (!validateOnInput) return;

    if (value.length === 0) {
      setIsValid(false);
      setShowValidation(false);
      return;
    }

    const valid = validateEmail(value);
    setIsValid(valid);
    setShowValidation(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      validateAndSubscribe();
    }
  };

  const inputClassnames = classnames(
    { 'globe-footer-mail-registration__input': isGlobe },
    { input: !isGlobe },
    { 'input--valid': showValidation && isValid },
    { 'input--error': showValidation && !isValid },
  );

  return (
    <>
      {isGlobe ? (
        <>
          <div>
            <span className="globe-footer-mail-registration__text">
              {labels.introText}
            </span>
          </div>
          {subscriptionSuccessful ? (
            <div className="footer-newsletter__success-container">
              <div className="globe-footer-newsletter__success-message">
                <p>{labels.successMessage}</p>
              </div>
            </div>
          ) : (
            <div className="globe-footer-mail-registration__div">
              <input
                id="email-input"
                className={inputClassnames}
                type="email"
                placeholder={labels.emailPlaceholder}
                maxLength="100"
                autoComplete="off"
                title="emailInput"
                value={inputValue}
                onChange={updateInput}
                onKeyPress={handleKeyPress}
              />
              <button
                type="submit"
                className="globe-footer-mail-registration__button"
                onClick={submit}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
                id="submitNewsletter"
                title="submitNewsletter"
              >
                OK
              </button>
              {showValidation && !isValid && (
                <span className="formbox__error">{labels.invalidMessage}</span>
              )}
            </div>
          )}
        </>
      ) : (
        <div
          className={classnames('footer-newsletter', {
            'footer-newsletter--standalone': standalone,
          })}
        >
          {!subscriptionSuccessful && (
            <div className="footer-newsletter__input-container">
              <label
                htmlFor="email-input"
                className="input__container footer-newsletter__input"
              >
                <input
                  type="text"
                  id={standalone ? 'email-input-standalone' : 'email-input'}
                  className={inputClassnames}
                  maxLength="100"
                  placeholder={labels.emailPlaceholder}
                  value={inputValue}
                  onChange={updateInput}
                  onKeyPress={handleKeyPress}
                  autoComplete="off"
                  title="emailInput"
                />
                {showValidation && (
                  <button
                    className="footer-newsletter__input-icon"
                    htmlFor="email-input"
                    type="button"
                  />
                )}
              </label>
              <button
                type="submit"
                className="footer-newsletter__submit"
                onClick={submit}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
                id="submitNewsletter"
                title="submitNewsletter"
              >
                <Icon path={COMMUNICATION_EMAIL} size="x-small" />
              </button>
            </div>
          )}
          {showValidation && !isValid && (
            <span className="formbox__error">{labels.invalidMessage}</span>
          )}
          {subscriptionSuccessful && (
            <div className="footer-newsletter__success-container">
              <div className="footer-newsletter__success-message">
                <p>{labels.successMessage}</p>
              </div>
            </div>
          )}
          <LinkList
            links={[labels?.dataPolicyLink]}
            className="footer-newsletter--data-policy-link"
          />
        </div>
      )}{' '}
    </>
  );
};

Newsletter.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  labels: PropTypes.shape({
    emailPlaceholder: PropTypes.string,
    invalidMessage: PropTypes.string,
    successMessage: PropTypes.string,
    dataPolicyLink: PropTypes.string,
    introText: PropTypes.string,
  }).isRequired,
  siteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  standalone: PropTypes.bool,
};

Newsletter.defaultProps = {
  standalone: false,
};

export default Newsletter;
